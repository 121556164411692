import userAxios from "./user-axios";
const API_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH_API_URL = API_URL + "/auth";

export const updateProfile = async (newProfileData) => {
  return await userAxios.post(`${AUTH_API_URL}/update_profile`, newProfileData);
};

export const verifyUserAttribute = async (code) => {
  return await userAxios.post(`${AUTH_API_URL}/verify_user_attribute`, {
    verification_code: code
  });
};

export const addUserSignup = async (addedUserData) => {
  return await userAxios.post(`${AUTH_API_URL}/add_user_signup`, addedUserData);
};

export const globalSignout = async () => {
  return await userAxios.post(`${AUTH_API_URL}/global_sign_out`);
};

export const getAccount = async () => {
  return await userAxios.get(`${AUTH_API_URL}/get_account`);
};

export const updatePassword = async (data) => {
  return await userAxios.post(`${AUTH_API_URL}/force_change_password`, data);
};

export const confirmEmail = async (data) => {
  return await userAxios.post(`${AUTH_API_URL}/confirm_email`, data);
};