import React, { lazy, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Redirect } from 'react-router-dom'

import * as userActions from '../store/user/actions'

// Routes
import AuthRoute from './AuthRoute'
import PublicRoute from './PublicRoute'
import UserRoute from './UserRoute'
import OnboardingRoute from './OnboardingRoute'
import OmniRoute from './OmniRoute'

// User pages
const MyAccountPage = lazy(() => import('../pages/user/MyAccount'))
const ShipWithAccountPage = lazy(() => import('../pages/user/ShipWithAccount'))
const OrderSuppliesPage = lazy(() => import('../pages/user/OrderSupplies'))
const AccountTrackingPage = lazy(() => import('../pages/user/AccountTracking'))
const SchedulePickupPage = lazy(() => import('../pages/user/SchedulePickup'))
const CancelPickupPage = lazy(() =>
  import('../pages/user/CancelScheduledPickup'),
)
const OnboardingPage = lazy(() => import('../pages/user/OnboardNewUser'))

// Auth
const ChooseNewPasswordPage = lazy(() =>
  import('../pages/auth/ChooseNewPassword'),
)
const ResetPasswordPage = lazy(() => import('../pages/auth/ResetPassword'))

// Public routes
const HomePage = lazy(() => import('../pages/Home'))

// Views are pages for embed in marketing site, should be public
const FuelSurcharge = lazy(() => import('../views/FuelSurcharge'))
const QuickAccess = lazy(() => import('../views/HomeQuickAccess'))
const ShipNowView = lazy(() => import('../views/ShipNow'))
const SchedulePickup = lazy(() => import('../views/SchedulePickup'))
const CancelPickup = lazy(() => import('../views/CancelPickup'))
const CreateAccount = lazy(() => import('../views/CreateAccount'))
const Claim = lazy(() => import('../views/Claim'))
const BranchLocater = lazy(() => import('../views/BranchLocater'))
const UserMenu = lazy(() => import('../views/UserMenuContent'))
const CreateAccountSuccessPage = lazy(() =>
  import('../components/create-account/CreateAccountSuccess'),
)
const CalculateRates = lazy(() => import('../views/CalculateRates'))
const TrackingPage = lazy(() => import('../views/Tracking'))
const SearchLocWidget = lazy(() => import('../views/SearchServiceArea'))
const TrackAndSearchWidget = lazy(() => import('../views/TrackAndSearch'))
const CustomerCookieProgram = lazy(() =>
  import('../views/CustomerCookieProgram'),
)

function AppRouteSwitcher() {
  const authenticated = useSelector(({ auth }) => auth.isLoggedIn)
  const dispatch = useDispatch()

  useEffect(() => {
    if (authenticated) {
      dispatch(userActions.getAccount())
    }
  }, [authenticated, dispatch])

  return (
    <Switch>
      <OmniRoute path="/" exact component={HomePage} />
      <PublicRoute
        path="/homepage-quick-access"
        exact
        component={QuickAccess}
      />
      <PublicRoute path="/ship-now" exact component={ShipNowView} />
      <PublicRoute path="/schedule-pickup" exact component={SchedulePickup} />
      <PublicRoute path="/cancel-pickup" exact component={CancelPickup} />
      <PublicRoute path="/create-lso-account" exact component={CreateAccount} />
      <PublicRoute path="/branch-locater" exact component={BranchLocater} />
      <PublicRoute path="/claim" exact component={Claim} />
      <PublicRoute path="/user-menu" exact component={UserMenu} />
      <PublicRoute
        path="/activate-new-account"
        exact
        component={CreateAccountSuccessPage}
      />
      <PublicRoute path="/tracking" exact component={TrackingPage} />
      <PublicRoute path="/calculate-rates" exact component={CalculateRates} />
      <PublicRoute
        path="/search-shipment-location"
        exact
        component={SearchLocWidget}
      />      
      <PublicRoute
        path="/track-and-search"
        exact
        component={TrackAndSearchWidget}
      />
      <PublicRoute path="/fuel-charge" exact component={FuelSurcharge} />

      <PublicRoute
        path="/customer-cookie-program"
        exact
        component={CustomerCookieProgram}
      />
      <AuthRoute path="/reset-password" exact component={ResetPasswordPage} />
      <AuthRoute
        path="/choose-new-password"
        exact
        component={ChooseNewPasswordPage}
      />
      <UserRoute
        path="/ship-with-account"
        exact
        component={ShipWithAccountPage}
      />
      <UserRoute path="/my-account" component={MyAccountPage} />
      <UserRoute path="/order-supplies" exact component={OrderSuppliesPage} />
      <UserRoute path="/account-tracking" component={AccountTrackingPage} />
      <UserRoute
        path="/account-schedule-pickup"
        exact
        component={SchedulePickupPage}
      />
      <UserRoute
        path="/account-cancel-a-pickup"
        exact
        component={CancelPickupPage}
      />
      <OnboardingRoute
        path="/new-user-onboarding"
        exact
        component={OnboardingPage}
      />
      <Redirect to="/" />
    </Switch>
  )
}

export default AppRouteSwitcher
