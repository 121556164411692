import * as userService from "../../services/user.service";
import { logout } from "../auth/actions";
import {
  CONFIRM_EMAIL,
  GET_ACCOUNT_FAIL,
  GET_ACCOUNT_START,
  GET_ACCOUNT_SUCCESS,
  UPDATE_PASSWORD,
} from "./types";

export const getAccount = () => async (dispatch) => {
  dispatch({
    type: GET_ACCOUNT_START,
  });
  try {
    const response = await userService.getAccount();
    if (response.status >= 200 && response.status < 300) {
      const {
        data: { data },
      } = response;
      dispatch({
        type: GET_ACCOUNT_SUCCESS,
        account: data,
      });
    }
  } catch (error) {
    // console.log("we failed here, out!")
    // If we can't get account then we should log the user out
    dispatch({ type: GET_ACCOUNT_FAIL });
    dispatch(logout());
  }
};

export const updatePassword = (newPassword) => async (dispatch) => {
  try {
    const response = await userService.updatePassword({
      new_password: newPassword,
    });
    if (response.status >= 200 && response.status < 300) {
      dispatch({
        type: UPDATE_PASSWORD,
      });
    }
  } catch (error) {
    const errorData = (error.response && error.response.data) || error.message;
    throw errorData;
  }
};

export const confirmEmail = (email) => async (dispatch) => {
  try {
    const response = await userService.confirmEmail({ email });
    if (response.status >= 200 && response.status < 300) {
      dispatch({
        type: CONFIRM_EMAIL,
      });
    }
  } catch (error) {
    const errorData = (error.response && error.response.data) || error.message;
    throw errorData;
  }
};
