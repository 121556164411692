import {
  RETRIEVE_SERVICE_AREA_BY_ZIP_FAIL,
  RETRIEVE_SERVICE_AREA_BY_ZIP_SUCCESS,
} from "./types";

const initialState = {
  serviceAreas: {},
  branches: {},
};

const locatorReducer = (state = initialState, action) => {
  let zipCountry, serviceArea;
  switch (action.type) {
    case RETRIEVE_SERVICE_AREA_BY_ZIP_SUCCESS:
      zipCountry = [action.zip, action.countryCode].join("-");
      serviceArea = state.serviceAreas[zipCountry] || {};

      return {
        ...state,
        serviceAreas: {
          ...state.serviceAreas,
          [zipCountry]: {
            ...serviceArea,
            ...action.serviceArea,
          },
        },
      };

    case RETRIEVE_SERVICE_AREA_BY_ZIP_FAIL:
      zipCountry = [action.zip, action.countryCode].join("-");
      serviceArea = state.serviceAreas[zipCountry] || {};

      return {
        ...state,
        serviceAreas: {
          ...state.allowedServices,
          [zipCountry]: serviceArea,
        },
      };

    default:
      return state;
  }
};

export default locatorReducer;
