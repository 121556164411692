import { RESET_USER_DATA } from "../user/types";
import {
  ADD_GROUP_MEMBERS,
  GET_GROUP_MEMBERS,
  CREATE_GROUP_SUCCESS,
  DELETE_ADDRESS_GROUP_SUCCESS,
  GET_ADDRESS_GROUP,
  GET_ADDRESS_GROUP_START,
  DELETE_GROUP_MEMBERS,
  FETCH_GROUP_MEMBERS_SUCCESS,
  GET_GROUPS_WITH_MEMBERS_SUCCESS,
  GET_GROUPS_WITH_MEMBERS_START,
  GET_GROUPS_WITH_MEMBERS_FAIL,
} from "./types";

const initialState = {
  groups: [],
  fetched: false,
  groupMembers: [],
  members: {},
  groupsWithMembers: [],
  fetchingWithMembers: false,
  fetchedWithMembers: false,
};

function groupReducer(state = initialState, action) {
  const { type, data, info } = action;
  switch (type) {
    case GET_ADDRESS_GROUP:
      return {
        ...state,
        groups: data.map((group) => ({
          ...group,
          value: group.GroupId,
          label: group.GroupName,
        })),
        fetched: true,
      };
    case GET_ADDRESS_GROUP_START:
      return {
        ...state,
        fetched: false,
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, data],
      };
    case ADD_GROUP_MEMBERS:
      return {
        ...state,
      };
    case GET_GROUP_MEMBERS:
      return {
        ...state,
        groupMembers: data ? data : [],
        fetched: true,
      };
    case DELETE_GROUP_MEMBERS:
      return {
        ...state,
        groupMembers: data ? data : [],
        fetched: true,
      };
    case DELETE_ADDRESS_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter((item) => item.label !== info.group_name),
      };
    case FETCH_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        members: {
          ...state.members,
          [action.groupId]: action.members,
        },
      };
    case GET_GROUPS_WITH_MEMBERS_START:
      return {
        ...state,
        fetchedWithMembers: false,
        fetchingWithMembers: true,
      };
    case GET_GROUPS_WITH_MEMBERS_SUCCESS:
      return {
        ...state,
        groupsWithMembers: data.map((group) => ({
          ...group,
          value: group.GroupId,
          label: group.GroupName,
        })),
        fetchedWithMembers: true,
        fetchingWithMembers: false,
      };
    case GET_GROUPS_WITH_MEMBERS_FAIL:
      return {
        ...state,
        fetchedWithMembers: true,
        fetchingWithMembers: false,
      };
    case RESET_USER_DATA:
      return initialState
    default:
      return state;
  }
}

export default groupReducer;
