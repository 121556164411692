import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const OmniRoute = ({ component: Component, componentProps, ...routeProps }) => {
  const { authenticated, onboardingRequired } = useSelector(
    ({ auth, user }) => ({
      authenticated: auth.isLoggedIn,
      onboardingRequired:
        user.profile && user.profile["change_password_required"],
    })
  );

  return (
    <Route
      {...routeProps}
      render={(props) =>
        authenticated && onboardingRequired ? (
          <Redirect
            to={{
              pathname: "/new-user-onboarding",
              state: {
                from: props.location,
              },
            }}
          />
        ) : (
          <Component {...props} {...componentProps} />
        )
      }
    />
  );
};

export default OmniRoute;
