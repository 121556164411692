export const themeColors = () => {
  let rootStyle = getComputedStyle(document.body);
  return {
    darkBlue: rootStyle.getPropertyValue("--dark-blue").trim(),
    mediumBlue: rootStyle.getPropertyValue("--medium-blue").trim(),
    lightBlue: rootStyle.getPropertyValue("--light-blue").trim(),
    black: rootStyle.getPropertyValue("--black").trim(),
    lightestBlue: rootStyle.getPropertyValue("--lightest-blue").trim(),
    white: rootStyle.getPropertyValue("--white").trim(),
    jungleGreen: rootStyle.getPropertyValue("--jungle-green").trim(),
    mediumGray: rootStyle.getPropertyValue("--medium-gray").trim(),
    darkGray: rootStyle.getPropertyValue("--dark-gray").trim(),
    lightGray: rootStyle.getPropertyValue("--light-gray").trim(),
    bgGray: rootStyle.getPropertyValue("--bg-gray").trim(),
    navGray: rootStyle.getPropertyValue("--nav-gray").trim(),
    semiDarkGray: rootStyle.getPropertyValue("--semi-dark-gray").trim(),
    submarine: rootStyle.getPropertyValue("--submarine").trim(),
    iron: rootStyle.getPropertyValue("--iron").trim(),
  };
};
