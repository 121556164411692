import axios from 'axios'
const API_URL = process.env.REACT_APP_API_BASE_URL
const SHIPPING_API_URL = API_URL + '/shipping_service'

export const checkZip = async (zipData) => {
  return await axios.post(`${SHIPPING_API_URL}/check_zip`, zipData)
}

export const getAllowedServices = async (data) => {
  const params = new URLSearchParams(data).toString()
  return await axios.get(`${SHIPPING_API_URL}/get_allowed_services?${params}`)
}

export const getServicesList = async () => {
  return await axios.get(`${SHIPPING_API_URL}/get_service_list`)
}

export const getServiceByZip = async (data) => {
  const params = new URLSearchParams(data).toString()
  return await axios.get(`${SHIPPING_API_URL}/service_by_zip?${params}`);
}