import DefNewsImg from "../../assets/img/content/featured-card.png";
import {
  GET_FEATURED_NEWS_FAIL,
  GET_FEATURED_NEWS_START,
  GET_FEATURED_NEWS_SUCCESS,
} from "./types";

const MARKETINGLINK = process.env.REACT_APP_MARKETING_SITE_URL;

const initialState = {
  featured: {
    title: "Latest LSO News",
    img: DefNewsImg,
    link: MARKETINGLINK,
  },
  fetchedFeatured: false,
};

function newsReducer(state = initialState, action) {
  let featuredNewsData;
  switch (action.type) {
    case GET_FEATURED_NEWS_START:
      return {
        ...state,
        fetchedFeatured: false,
      };
    case GET_FEATURED_NEWS_SUCCESS:
      featuredNewsData = action.news
        ? {
            title: action.news.name,
            img: action.news["featured-image"].url,
            link: `${MARKETINGLINK}/news/${action.news.slug}`,
          }
        : action.featured;
      return {
        ...state,
        featured: featuredNewsData,
        fetchedFeatured: true,
      };
    case GET_FEATURED_NEWS_FAIL:
      return {
        ...state,
        fetchedFeatured: true,
      };
    default:
      return state;
  }
}

export default newsReducer;
