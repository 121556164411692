import { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

import "react-toastify/dist/ReactToastify.min.css";
import "./assets/scss/theme.scss";
import ScrollToTop from "./components/routes/ScrollToTop";
import AppRouteSwitcher from "./routes/AppRouteSwitcher";

function App() {
  const gtmParams = { id: "GTM-K4HCT4Z" };

  return (
    <div className="App">
      <GTMProvider state={gtmParams}>
        <BrowserRouter>
          <ScrollToTop />
          <Suspense fallback={<div className="loading" />}>
            <Route render={(props) => <AppRouteSwitcher {...props} />} />
          </Suspense>
          <ToastContainer autoClose={3000} />
        </BrowserRouter>
      </GTMProvider>
    </div>
  );
}

export default App;
