export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL',
  GET_ADDRESS_GROUP = 'GET_ADDRESS_GROUP',
  GET_ADDRESS_GROUP_START = 'GET_ADDRESS_GROUP_START',
  GET_ADDRESS_GROUP_FAIL = 'GET_ADDRESS_GROUP_FAIL',
  DELETE_ADDRESS_GROUP_SUCCESS = 'DELETE_ADDRESS_GROUP_SUCCESS',
  DELETE_ADDRESS_GROUP_FAIL = 'GET_ADDRESS_GROUP_FAIL',
  ADD_GROUP_MEMBERS = 'ADD_GROUP_MEMBERS',
  GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS',
  DELETE_GROUP_MEMBERS = 'DELETE_GROUP_MEMBERS',
  GROUP_PROCESS_FAIL = 'GROUP_PROCESS_FAIL',
  FETCH_GROUP_MEMBERS_SUCCESS = "FETCH_GROUP_MEMBERS_SUCCESS",
  FETCH_GROUP_MEMBERS_FAIL = "FETCH_GROUP_MEMBERS_FAIL",
  GET_GROUPS_WITH_MEMBERS_START = "GET_GROUPS_WITH_MEMBERS_START",
  GET_GROUPS_WITH_MEMBERS_SUCCESS = "GET_GROUPS_WITH_MEMBERS_SUCCESS",
  GET_GROUPS_WITH_MEMBERS_FAIL = "GET_GROUPS_WITH_MEMBERS_FAIL";