import {
  ADD_ADDITIONAL_USER,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
  CHANGE_USER_PASSWORD_SUCCESS,
  GET_SERVICE_LIST,
  PREFERENCE_FAIL,
  REQUEST_RESET_PASSWORD_FAIL,
  REQUEST_RESET_PASSWORD_SUCCESS,
  RESEND_VERIFICATION_CODE_FAIL,
  RESEND_VERIFICATION_CODE_SUCCESS,
  SIGNIN_FAIL,
  SIGNIN_SUCCESS,
  SIGNOUT,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_USER_PREFERENCE,
  FETCHDATAHUB_SUCCESS
} from './types'
import * as authService from '../../services/auth.service'
import * as shippingService from '../../services/shipping.service'
import { getAccount } from '../user/actions'
import { RESET_USER_DATA } from '../user/types'

export const fetchdatahub = () => async (dispatch) => {
  try {
    const response = await authService.fetchdatahub()
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      dispatch({
        type: FETCHDATAHUB_SUCCESS,
        payload: {
          user: data,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const signup = (signupData) => async (dispatch) => {
  try {
    const response = await authService.signup(signupData)
    if (response.status >= 200 && response.status < 300) {
      const { data } = response
      dispatch({ type: SIGNUP_SUCCESS })
      return data
    }
  } catch (error) {
    dispatch({ type: SIGNUP_FAIL })
    const errorData = (error.response && error.response.data) || error.message
    // console.log(errorData)
    throw errorData
  }
}

export const signin = (credentials) => async (dispatch) => {
  try {
    const response = await authService.signin(credentials)
    if (response.status >= 200 && response.status < 300) {
      const {
        data: { data },
      } = response
      localStorage.setItem('user', JSON.stringify(data))
      dispatch({
        type: SIGNIN_SUCCESS,
        payload: {
          user: data,
        },
      })
    }
  } catch (error) {
    dispatch({ type: SIGNIN_FAIL })
    const message = error.response && error.response.data
    throw message
  }
}

export const logout =
  (loggedOutManually = false) =>
  (dispatch) => {
    localStorage.removeItem('user')
    dispatch({
      type: SIGNOUT,
      loggedOutManually,
    })
    dispatch({ type: RESET_USER_DATA })
  }

export const resendVerificationEmail = (email) => async (dispatch) => {
  try {
    const response = await authService.resendConfirmationCode(email)
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: RESEND_VERIFICATION_CODE_SUCCESS })
    }
  } catch (error) {
    dispatch({ type: RESEND_VERIFICATION_CODE_FAIL })
    const message = error.response && error.response.data
    throw message
  }
}

export const requestResetPassword = (creds) => async (dispatch) => {
  try {
    const response = await authService.forgotPassword(creds)
    if (response.status >= 200 && response.status < 300) {
      const { data } = response
      dispatch({ type: REQUEST_RESET_PASSWORD_SUCCESS })
      return data && data.data
    }
  } catch (error) {
    dispatch({ type: REQUEST_RESET_PASSWORD_FAIL })
    const message = error.response && error.response.data
    throw message
  }
}

export const changePassword = (changePassData) => async (dispatch) => {
  try {
    const response = await authService.confirmForgotPassword(changePassData)
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: CHANGE_PASSWORD_SUCCESS })
    }
  } catch (error) {
    dispatch({ type: CHANGE_PASSWORD_FAIL })
    const message = error.response && error.response.data
    throw message
  }
}

export const changeUserPassword = (creds) => async (dispatch) => {
  try {
    const response = await authService.changeUserPassword(creds)
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: CHANGE_USER_PASSWORD_SUCCESS })
    }
  } catch (error) {
    dispatch({ type: CHANGE_USER_PASSWORD_FAIL })
    const message = error.response && error.response.data
    throw message
  }
}

export const updateProfile = (creds) => async (dispatch) => {
  try {
    const response = await authService.updateProfile(creds)
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: UPDATE_PROFILE })
    }
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE })
    const message = error.response && error.response.data
    throw message
  }
}

export const updateUserPref = (params) => async (dispatch) => {
  try {
    const response = await authService.updateUserPref(params)
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: UPDATE_USER_PREFERENCE })
      await dispatch(getAccount())
    }
  } catch (error) {
    // dispatch({ type: UPDATE_PROFILE })
    console.log(error)
    const message = error.response && error.response.data
    throw message
  }
}

//GET SERVICES LIST
export const getServicesList = () => async (dispatch) => {
  try {
    const response = await shippingService.getServicesList()
    const services = response.data.response
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: GET_SERVICE_LIST, services })
    }
  } catch (error) {
    dispatch({ type: PREFERENCE_FAIL })
    console.log(error)
    const message = error.response && error.response.data
    throw message
  }
}

export const addAdditionalUser = (userData) => async (dispatch) => {
  try {
    const response = await authService.customerAddUser(userData)
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: ADD_ADDITIONAL_USER })
    }
  } catch (error) {
    // dispatch({ type: UPDATE_PROFILE })
    console.log(error)
    const message = error.response && error.response.data
    throw message
  }
}
