import React from "react";
import styled from "styled-components";
import { themeColors } from "../../helpers/themeColors";

const colors = themeColors();

const LoadingMsg = styled.h2`
  position: absolute;
  left: calc(50%);
  top: calc(50% - 50px);
  transform: translate(-50%, -50%);
  color: ${colors.darkBlue};
`;

const DisabledLoadingOverlay = ({
  isLoading,
  children,
  overlayStyles,
  loadingMsg,
  innerLoadingStyles,
}) => {
  return (
    <div className="position-relative">
      {children}
      {isLoading && (
        <div className="loading-overlay" style={overlayStyles}>
          {loadingMsg && <LoadingMsg>{loadingMsg}</LoadingMsg>}
          <div className="inner-loading" style={innerLoadingStyles} />
        </div>
      )}
    </div>
  );
};

export const FormLoadingOverlay = ({
  isLoading,
  loadingMsg,
  children,
  overlayStyles,
  innerLoadingStyles,
}) => {
  return (
    <>
      {children}
      {isLoading && (
        <div className="loading-overlay" style={overlayStyles}>
          {loadingMsg && <LoadingMsg>{loadingMsg}</LoadingMsg>}
          <div className="inner-loading" style={innerLoadingStyles} />
        </div>
      )}
    </>
  );
};

export default DisabledLoadingOverlay;
