import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import DisabledLoadingOverlay from "../components/common/DisabledLoadingOverlay";

const UserRoute = ({ component: Component, componentProps, ...routeProps }) => {
  const { authenticated, onboardingRequired, loadingProfile } = useSelector(
    ({ auth, user }) => ({
      authenticated: auth.isLoggedIn,
      onboardingRequired:
        user.profile && user.profile["change_password_required"],
      loadingProfile: user.loadingProfile,
    })
  );

  return (
    <Route
      {...routeProps}
      render={(props) =>
        authenticated ? (
          <>
            {onboardingRequired ? (
              <Redirect
                to={{
                  pathname: "/new-user-onboarding",
                  state: {
                    from: props.location,
                  },
                }}
              />
            ) : (
              <DisabledLoadingOverlay isLoading={loadingProfile}>
                <Component {...props} {...componentProps} />
              </DisabledLoadingOverlay>
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                openLoginForm: true,
              },
            }}
          />
        )
      }
    />
  );
};

export default UserRoute;
