import {
  CHECK_ZIP_SUCCESS,
  GET_ALLOWED_SERVICES_FAIL,
  GET_ALLOWED_SERVICES_SUCCESS,
  SEARCH_TRACKING,
} from './types'

const initialState = {
  allowedServices: {},
  checkZip: {},
  searchTracking: 'tracking-form',
  trackingNo: '',
}

const shippingReducer = (state = initialState, action) => {
  let pairName, allowedServices

  switch (action.type) {
    case GET_ALLOWED_SERVICES_SUCCESS:
      pairName = [action.origZip, action.destZip, action.toCode].join('-')
      allowedServices = state.allowedServices[pairName] || []

      return {
        ...state,
        allowedServices: {
          ...state.allowedServices,
          [pairName]: [...allowedServices, ...action.services],
        },
      }

    case GET_ALLOWED_SERVICES_FAIL:
      pairName = [action.origZip, action.destZip, action.toCode].join('-')
      allowedServices = state.allowedServices[pairName] || []

      return {
        ...state,
        allowedServices: {
          ...state.allowedServices,
          [pairName]: allowedServices,
        },
      }

    case CHECK_ZIP_SUCCESS:
      pairName = [action.zip, action.country].join('-')

      return {
        ...state,
        checkZip: {
          ...state.checkZip,
          [pairName]: action.isValid || null,
        },
      }

    case SEARCH_TRACKING:
      return {
        ...state,
        searchTracking: action.view,
        trackingNo: action.trackingNum,
      }

    default:
      return state
  }
}

export default shippingReducer
