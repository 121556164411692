export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  SIGNUP_FAIL = 'SIGNUP_FAIL',
  SIGNIN_SUCCESS = 'SIGNIN_SUCCESS',
  SIGNIN_FAIL = 'SIGNIN_FAIL',
  SIGNOUT = 'SIGNOUT',
  REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS',
  REQUEST_RESET_PASSWORD_FAIL = 'REQUEST_RESET_PASSWORD_FAIL',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL',
  GET_NEW_CODE = 'GET_NEW_CODE',
  VERIFY_PHONE = 'VERIFY_PHONE',
  RESEND_VERIFICATION_CODE_SUCCESS = 'RESEND_VERIFICATION_CODE_SUCCESS',
  RESEND_VERIFICATION_CODE_FAIL = 'RESEND_VERIFICATION_CODE_FAIL',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_FAIL = 'CHANGE_USER_PASSWORD_FAIL',
  UPDATE_USER_PREFERENCE = 'UPDATE_USER_PREFERENCE',
  ADD_ADDITIONAL_USER = 'ADD_ADDITIONAL_USER',
  GET_SERVICE_LIST = ' GET_SERVICE_LIST',
  PREFERENCE_FAIL = 'PREFERENCE_FAIL',
  FETCHDATAHUB_SUCCESS = 'FETCHDATAHUB_SUCCESS'
