import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  SIGNOUT,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  UPDATE_PROFILE,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAIL,
  GET_SERVICE_LIST,
  FETCHDATAHUB_SUCCESS
} from './types'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = user
  ? {
      isLoggedIn: true,
      user,
      services: [],
    }
  : { isLoggedIn: false, user: null, services: [] }

function authReducer(state = initialState, action) {
  const { type, payload, services } = action

  switch (type) {
    case FETCHDATAHUB_SUCCESS:
      return {
        ...state,
        user: payload.user,
      }

    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      }
    case GET_SERVICE_LIST:
      return {
        ...state,
        services: services.map((item, index) => ({
          value: item.ServiceID,
          label: item.ServDescription,
          serviceID: item.ServiceID,
        })),
      }
    case SIGNUP_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      }
    case SIGNIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      }
    case SIGNIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    case SIGNOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        loggedOutManually: action.loggedOutManually,
        services: []
      }
    case CHANGE_USER_PASSWORD_SUCCESS:
    case CHANGE_USER_PASSWORD_FAIL:
    case UPDATE_PROFILE:
      return {
        ...state,
      }
    case REQUEST_RESET_PASSWORD_SUCCESS:
    case REQUEST_RESET_PASSWORD_FAIL:
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    default:
      return state
  }
}

export default authReducer
