import { combineReducers } from 'redux'
import authReducer from './auth/reducer'
import userReducer from './user/reducer'
import shippingReducer from './shipping/reducer'
import addressbookReducer from './addressbook/reducer'
import groupReducer from './group/reducer'
import locatorReducer from './locator/reducer'
import newsReducer from './news/reducer'
import pricingReducer from './pricing/reducer'

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  shipping: shippingReducer,
  addressbook: addressbookReducer,
  group: groupReducer,
  locator: locatorReducer,
  news: newsReducer,
  pricing: pricingReducer,
})
