import { FUEL_SURCHARGE_SUCCESS } from './types'

const initialState = {
  surcharges: [],
  fetched: false,
}

function addressbookReducer(state = initialState, action) {
  const { type, surcharges } = action
  switch (type) {
    case FUEL_SURCHARGE_SUCCESS:
      return {
        ...state,
        fetched: true,
        surcharges: surcharges,
      }

    default:
      return state
  }
}

export default addressbookReducer
