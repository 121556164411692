import axios from 'axios'
import userAxios from './user-axios'
const API_URL = process.env.REACT_APP_API_BASE_URL
const AUTH_API_URL = API_URL + '/auth'
//const API_URL = 'https://api-prod.lso.com'
//const AUTH_API_URL = 'https://api-prod.lso.com/auth'


export const fetchdatahub = async () => {
  try {
    const response = await userAxios.post('https://datahub.opera8.com/dashboardservice/page/customer/dashboard',
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; 
  }
}

export const signup = async (signupData) => {
  return await axios.post(AUTH_API_URL + '/signup', signupData)
}

export const confirmSignup = async ({ username, code }) => {
  return await axios.post(AUTH_API_URL + '/confirm_signup', {
    username,
    confirmation_code: code,
  })
}

export const resendConfirmationCode = async (username) => {
  return await axios.post(AUTH_API_URL + '/resend_confirmation_code', {
    username,
  })
}

export const signin = async ({ customerId, username, password, keep }) => {
  return await axios.post(AUTH_API_URL + '/signin', {
    customer_id: customerId,
    username,
    password,
    keep,
  })
}

export const forgotPassword = async (creds) => {
  return await axios.post(AUTH_API_URL + '/forgot_password', creds)
}

export const confirmForgotPassword = async ({
  username,
  password,
  confirmPassword,
  code,
  userId,
}) => {
  return await axios.post(AUTH_API_URL + '/confirm_forgot_password', {
    username,
    password,
    confirm_password: confirmPassword,
    confirmation_code: code,
    user_id: userId,
  })
}

export const changePassword = async ({ currentPassword, newPassword }) => {
  return await axios.post(AUTH_API_URL + '/change_password', {
    current_password: currentPassword,
    new_password: newPassword,
  })
}

export const changeUserPassword = async ({
  account_number,
  username,
  old_password,
  new_password,
}) => {
  return await userAxios.post(AUTH_API_URL + '/change_password', {
    account_number: account_number,
    username: username,
    old_password: old_password,
    new_password: new_password,
  })
}

export const updateProfile = async ({ oldEmail, newEmail }) => {
  return await userAxios.post(AUTH_API_URL + '/update_profile', {
    old_email: oldEmail,
    new_email: newEmail,
  })
}

export const updateUserPref = async (params) => {
  return await userAxios.post(AUTH_API_URL + '/update_user_preferences', params)
}

export const customerAddUser = async (userData) => {
  return await userAxios.post(AUTH_API_URL + '/customer_add_user', userData)
}

export const checkAcctNumValid = async (customerId) => {
  return await userAxios.post(AUTH_API_URL + '/check_user_profile', {
    account_number: customerId,
  })
}
