import { RESET_USER_DATA } from "../user/types";
import {
  ADD_ADDRESSBOOK_SUCCESS,
  EDIT_ADDRESSBOOK_SUCCESS,
  GET_ADDRESSBOOK,
  GET_ADDRESSBOOK_START,
  REMOVE_ADDRESSBOOK_SUCCESS,
} from "./types";

const initialState = {
  data: [],
  fetched: false,
  fetching: false,
};

function addressbookReducer(state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case ADD_ADDRESSBOOK_SUCCESS:
      return {
        ...state,
      };
    case GET_ADDRESSBOOK_START:
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    case GET_ADDRESSBOOK:
      return {
        ...state,
        data,
        fetched: true,
        fetching: false,
      };

    case EDIT_ADDRESSBOOK_SUCCESS:
      return {
        ...state,
        fetched: true,
      };
    case REMOVE_ADDRESSBOOK_SUCCESS:
      return {
        ...state,
      };
    case RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}

export default addressbookReducer;
