import axios from "axios";
import store from "../store";
import * as authActions from "../store/auth/actions";

const API_URL = process.env.REACT_APP_API_BASE_URL,
  TOKEN_KEY = "X-LSO-Authorization",
  ACCESSTOKEN = "AccessToken",
  TOKENTYPE = "TokenType",
  REFRESHTOKEN = "RefreshToken",
  EXPIREDTOKENMSG = "TokenExpiredError"

const userAxios = axios.create({
  baseURL: API_URL,
});

userAxios.interceptors.request.use(
  async (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user[ACCESSTOKEN] && user[TOKENTYPE]) {
      config.headers[TOKEN_KEY] = `${user[TOKENTYPE]} ${user[ACCESSTOKEN]}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

userAxios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errorData = error.response.data;
    if (
      errorData &&
      errorData.status === "error" &&
      errorData.message === EXPIREDTOKENMSG
    ) {
      console.log("Token has expired. Refreshing token.");
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && !user[REFRESHTOKEN]) {
          throw new Error("no-refresh-token");
        }
        const response = await fetch(`${API_URL}/auth/get_refresh_token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // [TOKEN_KEY]: `${user[TOKENTYPE]} ${user[ACCESSTOKEN]}`
          },
          body: JSON.stringify({
            refresh_token: user[REFRESHTOKEN],
          }),
        });
        if (response.status >= 200 && response.status < 300) {
          const { data: newTokens } = await response.json();
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...newTokens,
              [REFRESHTOKEN]: user[REFRESHTOKEN],
              username: user.username,
              cust_id: user.cust_id
            })
          );
          originalRequest._retry = true;
          originalRequest.headers[TOKEN_KEY] = `${newTokens[TOKENTYPE]} ${newTokens[ACCESSTOKEN]}`;
          console.log(
            "Token has been refreshed. Retrying your previous request now..."
          );

          return axios(originalRequest);
        } else {
          const data = await response.json();
          throw data.error;
        }
      } catch (refreshTokenError) {
        if (
          refreshTokenError &&
          refreshTokenError.message === "no-refresh-token"
        ) {
          return store.dispatch(authActions.logout());
        }
        return Promise.reject(refreshTokenError);
      }
    } else if (
      errorData &&
      errorData.success === false &&
      (errorData.message === "Not a valid JWT token" ||
        errorData.message === "Invalid Token.")
    ) {
      return store.dispatch(authActions.logout());
    }

    return Promise.reject(error);
  }
);

export default userAxios;
