import {
  GET_ACCOUNT_FAIL,
  GET_ACCOUNT_START,
  GET_ACCOUNT_SUCCESS,
  RESET_USER_DATA,
} from "./types";

const initState = {
  profile: [],
  loadingProfile: false,
};

function userReducer(state = initState, action) {
  switch (action.type) {
    case GET_ACCOUNT_START:
      return {
        ...state,
        loadingProfile: true,
      }
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.account,
        },
        loadingProfile: false,
      };
    case GET_ACCOUNT_FAIL:
    case RESET_USER_DATA:
      return initState;
    default:
      return state;
  }
}

export default userReducer;
